












































































import Vue from 'vue';
import Component from 'vue-class-component';
import FormElement, { SelectOption } from 'common-modules/src/components/CommonFormElement.vue';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import BackBar from '@/components/BackBar.vue';
import JwlButton from '@/components/JwlButton.vue';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';
import { EditProgramme } from '@/store/interfaces/Programme';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    BackBar,
    CommonError,
    CommonIcon,
    FormElement,
    JwlButton,
    JwlSubmitRow,
  },
})
export default class SisProgrammeEdit extends Vue {
  $refs!: {
    programmeForm: HTMLFormElement;
  }

  concentrations: SelectOption[] = [];
  error = null;
  formSubmitStatus = 0;
  loaded = false;
  participatingClcs = [];
  programme: EditProgramme|null = null;
  learningGroups: SelectOption[] = [];

  submitForm (): void {
    const { programmeForm } = this.$refs;

    if (programmeForm.reportValidity() && this.formSubmitStatus !== 1) {
      const submitForm = new FormData(programmeForm);
      this.formSubmitStatus = 1;
      let url = 'programme/new';
      if (!this.isNew) {
        url = `programme/edit/${this.$route.params.programme}`;
      }
      this.$store.dispatch('postData', {
        url,
        formData: submitForm,
      }).then((data) => {
        this.programme = data;
        this.participatingClcs = data.clcs || [];
        this.formSubmitStatus = 2;
        if (this.isNew) {
          this.$router.push({
            name: 'programmeEdit',
            params: {
              programme: data.id,
            },
          });
        }
        setTimeout(() => {
          this.formSubmitStatus = 0;
        }, 2500);
      }).catch((e) => {
        this.error = e;
        this.formSubmitStatus = -1;

        setTimeout(() => {
          this.formSubmitStatus = 0;
        }, 2000);
      });
    } else {
      this.formSubmitStatus = -2;
    }
  }

  hasLoaded (): void {
    this.$nextTick(() => {
      this.loaded = true;
    });
  }

  get notAllowedToEditStatus (): boolean {
    const { programme } = this;
    if (!programme) {
      return false;
    }
    const notAllowedToEditStatus = ['running', 'finished'];
    return notAllowedToEditStatus.includes(programme.status);
  }

  get isNew (): boolean {
    return this.$route.name === 'programmeNew';
  }

  created (): void {
    const fetchUrl = this.$route.name === 'programmeEdit'
      ? `programme/edit/${this.$route.params.programme}`
      : 'programme/new';
    this.$store.dispatch('getData', fetchUrl)
      .then((data) => {
        this.programme = data.programme;
        this.learningGroups = data.learningGroups;
        this.concentrations = data.concentrations;

        this.hasLoaded();
      })
      .catch((e) => {
        this.error = e;
      });
  }
}
